It
<template>

  <v-dialog
    width="1100"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Edit Item <span class="font-weight-black">{{ item.title }}</span></span>
        <span>for <span class="font-weight-black">{{ boardName }}</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col>
            <v-textarea
              outlined
              label="Title"
              v-model="form.title"
              :rules="rules.titleRules"
              :counter="1000"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col v-if="hasAlias">
            <v-text-field
              outlined
              label="Alias"
              v-model="form.alias"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="d-flex align-center">
            <v-switch
              inset
              color="secondary"
              class="mt-0 pa-0"
              label="Use alias"
              hide-details
              v-model="hasAlias"
            >
            </v-switch>
          </v-col>
        </v-row>

        <v-row class="d-flex">
          <v-col cols="10">
            <v-text-field
              outlined
              label="Tags"
              v-model="form.tags"
              :rules="rules.tagRules"
            ></v-text-field>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Cancel</v-btn>
        <v-btn depressed width="130" color="secondary" @click="updateItem">Update</v-btn>
      </v-card-actions>

    </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

function initialState () {
  return {
    saving: false,
    valid: false,
    hasAlias: false,
    form: {
      title: '',
      tags: '',
      alias: ''
    },
    rules: {
      titleRules: [
        v => !!v || 'Title is required',
        v => (v && v.length <= 1000) || 'Title must be less than 1000 characters'
      ],
      tagRules: [
        v => !!v || 'Tags are required'
      ]
    }
  }
}

export default Vue.extend({
  name: 'ItemEdit',
  props: {
    item: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  data: function () {
    return initialState()
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    boardName () {
      return this.item.board?.name
    }
  },
  methods: {
    resetModal () {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      Object.assign(this.$data, initialState())
      this.saving = false
    },
    updateItem () {
      const formPassedValidation = this.$refs.form.validate()
      if (formPassedValidation === false) {
        return false
      }
      this.saving = true
      if (!this.hasAlias) {
        this.form.alias = ''
      }
      axios({
        method: 'put',
        url: process.env.VUE_APP_BACKEND_URL + '/api/items/' + this.item.id,
        data: this.form
      }).then(() => {
        console.log('success')
        this.resetModal()
        this.show = false
        this.$emit('reload', true)
      }).catch(error => {
        console.log('error', error)
        // console.log(error.response.data)
        // console.log(error.response.status)
        // console.log(error.response.headers)
      })
    }
  },
  watch: {
    show (value) {
      if (value) {
        console.log('this.item', this.item)
        this.form.title = this.item.title
        this.form.tags = this.item.tags
        if (this.item.alias !== undefined && this.item.alias.length > 0) {
          this.hasAlias = true
          this.form.alias = this.item.alias
        }
      }
    }
  }
})
</script>
