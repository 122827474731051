var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"900","persistent":this.$store.state.modalNotCloseOutside,"no-click-animation":this.$store.state.modalWithoutAnimation},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"pa-8 veryDarkGrey--text",attrs:{"elevation":"0","rounded":"0"}},[_c('v-card-title',{staticClass:"d-flex justify-center headline flex-column mb-6 align-start"},[_c('span',[_vm._v("Share Permissions of "),_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.item.title))])]),_c('span',[_vm._v("for "),_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.boardName))])])]),_c('span',{staticClass:"modal-close-btn"},[_c('v-btn',{attrs:{"icon":"","color":"grey"},on:{"click":function($event){_vm.show=false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-tabs',{staticClass:"custom-tab mb-8",attrs:{"centered":"","color":"secondary"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_c('v-tab',[_c('span',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"d-flex mr-3"},[_vm._v("mdi-account")]),_vm._v(" Staff ")],1)]),_c('v-tab',[_c('span',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"d-flex mr-3"},[_vm._v("mdi-briefcase")]),_vm._v(" Workgroups ")],1)])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',{attrs:{"flat":"","elevation":"0"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.staffSample,"hide-default-footer":"","hide-default-header":"","footer-props":{
                      'items-per-page-options': [10, 20, 30]
                    }},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"d-flex align-center py-6 justify-start"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.user)+" ")]),(item.owner)?_c('v-chip',{staticClass:"ml-4"},[_vm._v("Owner")]):_vm._e()],1)]}},{key:"item.administrator",fn:function(ref){
                    var item = ref.item;
return [(item.administrator)?_c('span',{staticClass:"py-6 d-flex flex-column"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"28","color":"secondary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-shield-account ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.user)+" is administrator ")])])],1):_vm._e()]}},{key:"item.workgroup",fn:function(ref){
                    var item = ref.item;
return [(item.workgroup)?_c('span',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"py-6 d-flex flex-column"},[_c('v-icon',{staticClass:"d-flex mr-3",attrs:{"color":"secondary"}},[_vm._v("mdi-briefcase")])],1),_c('span',[_vm._v(" Member of Workgroup"),_c('br'),_c('span',{staticClass:"font-weight-bold text-uppercase"},[_vm._v("Name of The Workgroup")])])]):_vm._e()]}},{key:"item.view",fn:function(ref){
                    var item = ref.item;
return [(item.workgroup)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"size":"32","icon":""}},'v-btn',attrs,false),on),[(item.view)?_c('v-icon',{attrs:{"size":"22","color":"secondary"}},[_vm._v(" mdi-eye ")]):_vm._e(),(!item.view)?_c('v-icon',{attrs:{"size":"22","color":"lightGrey"}},[_vm._v(" mdi-eye-off ")]):_vm._e()],1)]}}],null,true)},[(item.view)?_c('span',[_vm._v("Members from Workgroup can VIEW")]):_vm._e(),(!item.view)?_c('span',[_vm._v("Members from Workgroup can't VIEW")]):_vm._e()]):_vm._e(),(!item.workgroup)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","fab":"","small":""},on:{"click":function($event){item.view = !item.view}}},'v-btn',attrs,false),on),[(item.view)?_c('v-icon',{attrs:{"size":"22","color":"success"}},[_vm._v(" mdi-eye ")]):_vm._e(),(!item.view)?_c('v-icon',{attrs:{"size":"22","color":"lightGrey"}},[_vm._v(" mdi-eye-off ")]):_vm._e()],1)]}}],null,true)},[(item.view)?_c('span',[_vm._v(_vm._s(item.user)+" can VIEW")]):_vm._e(),(!item.view)?_c('span',[_vm._v(_vm._s(item.user)+" can't VIEW")]):_vm._e()]):_vm._e()]}},{key:"item.edit",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","fab":"","small":""},on:{"click":function($event){item.edit = !item.edit}}},'v-btn',attrs,false),on),[(item.edit)?_c('v-icon',{attrs:{"size":"22","color":"success"}},[_vm._v(" mdi-pencil ")]):_vm._e(),(!item.edit)?_c('v-icon',{attrs:{"size":"22","color":"lightGrey"}},[_vm._v(" mdi-pencil-off ")]):_vm._e()],1)]}}],null,true)},[(item.edit)?_c('span',[_vm._v(_vm._s(item.user)+" can EDIT")]):_vm._e(),(!item.edit)?_c('span',[_vm._v(_vm._s(item.user)+" can't EDIT")]):_vm._e()])]}}])})],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[[_c('v-row',{staticClass:"py-2"},[_c('v-col',[_c('v-switch',{staticClass:"mt-0 pa-0",attrs:{"inset":"","label":"City Manager Office","color":"secondary","hide-details":""}}),_c('p',{staticClass:"important--text font-italic pl-14 mb-0"},[_vm._v("Deactivated")])],1),_c('v-col',{staticClass:"py-0 d-flex align-center text-caption"},[_vm._v(" Sed nec est sit amet magna facilisis mattis. Pellentesque efficitur metus urna. Duis in tortor nunc. Vivamus sit amet nisl ac ante maximus molestie. Proin sed diam quis magna tristique fringilla. ")])],1),_c('v-row',[_c('v-divider',{staticClass:"custom-divider"})],1),_c('v-row',{staticClass:"py-2"},[_c('v-col',[_c('v-switch',{staticClass:"mt-0 pa-0",attrs:{"inset":"","label":"City Secretary Office","color":"secondary","hide-details":""}})],1),_c('v-col',{staticClass:"py-0 d-flex align-center text-caption"},[_vm._v(" Suspendisse libero urna, scelerisque posuere ligula aliquet, condimentum elementum sem. Proin rutrum ac mauris eu feugiat. ")])],1)]],2)],1)],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex align-center justify-center"},[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","width":"130"},on:{"click":function($event){_vm.show=false}}},[_vm._v("Ok")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }