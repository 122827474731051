<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Share Permissions of <span class="font-weight-black">{{ item.title }}</span></span>
        <span>for <span class="font-weight-black">{{ boardName }}</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col>

              <v-tabs
                v-model="tab"
                centered
                color="secondary"
                class="custom-tab mb-8"
              >
                <v-tabs-slider></v-tabs-slider>

                <v-tab>
                  <span class="d-flex align-center">
                    <v-icon class="d-flex mr-3">mdi-account</v-icon>
                    Staff
                  </span>
                </v-tab>

                <v-tab>
                  <span class="d-flex align-center">
                    <v-icon class="d-flex mr-3">mdi-briefcase</v-icon>
                    Workgroups
                  </span>
                </v-tab>

              </v-tabs>

              <v-tabs-items v-model="tab">

                <v-tab-item
                >
                  <v-card flat elevation="0">
                    <v-data-table
                      :headers="headers"
                      :items="staffSample"
                      hide-default-footer
                      hide-default-header
                      class="elevation-0"
                      :footer-props="{
                        'items-per-page-options': [10, 20, 30]
                      }"
                    >
                      <template v-slot:item.user="{ item }">
                        <span class="d-flex align-center py-6 justify-start">
                          <span class="font-weight-bold">
                            {{ item.user }}
                          </span>
                          <v-chip v-if="item.owner" class="ml-4">Owner</v-chip>
                        </span>
                      </template>
                      <template v-slot:item.administrator="{ item }">
                        <span class="py-6 d-flex flex-column" v-if="item.administrator">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  size="28"
                                  v-bind="attrs"
                                  v-on="on"
                                  color="secondary"
                                >
                                  mdi-shield-account
                                </v-icon>
                            </template>
                            <span>
                              {{ item.user }} is administrator
                            </span>
                          </v-tooltip>
                        </span>
                      </template>
                      <template v-slot:item.workgroup="{ item }">
                        <span class="d-flex align-center" v-if="item.workgroup">
                          <span class="py-6 d-flex flex-column">
                            <v-icon color="secondary" class="d-flex mr-3">mdi-briefcase</v-icon>
                          </span>
                          <span>
                            Member of Workgroup<br>
                            <span class="font-weight-bold text-uppercase">Name of The Workgroup</span>
                          </span>
                        </span>
                      </template>
                      <template v-slot:item.view="{ item }">

                        <v-tooltip top v-if="item.workgroup">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              size="32"
                              v-bind="attrs"
                              v-on="on"
                              icon
                            >
                              <v-icon
                                size="22"
                                color="secondary"
                                v-if="item.view"
                              >
                                mdi-eye
                              </v-icon>
                              <v-icon
                                size="22"
                                color="lightGrey"
                                v-if="!item.view"
                              >
                                mdi-eye-off
                              </v-icon>
                            </v-btn>
                          </template>
                          <span v-if="item.view">Members from Workgroup can VIEW</span>
                          <span v-if="!item.view">Members from Workgroup can't VIEW</span>
                        </v-tooltip>

                        <v-tooltip top v-if="!item.workgroup">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              depressed
                              fab
                              small
                              v-bind="attrs"
                              v-on="on"
                              @click="item.view = !item.view"
                            >
                              <v-icon
                                size="22"
                                color="success"
                                v-if="item.view"
                              >
                                mdi-eye
                              </v-icon>
                              <v-icon
                                size="22"
                                color="lightGrey"
                                v-if="!item.view"
                              >
                                mdi-eye-off
                              </v-icon>
                            </v-btn>
                          </template>
                          <span v-if="item.view">{{ item.user }} can VIEW</span>
                          <span v-if="!item.view">{{ item.user }} can't VIEW</span>
                        </v-tooltip>

                      </template>
                      <template v-slot:item.edit="{ item }">

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              depressed
                              fab
                              small
                              v-bind="attrs"
                              v-on="on"
                              @click="item.edit = !item.edit"
                            >
                              <v-icon
                                size="22"
                                color="success"
                                v-if="item.edit"
                              >
                                mdi-pencil
                              </v-icon>
                              <v-icon
                                size="22"
                                color="lightGrey"
                                v-if="!item.edit"
                              >
                                mdi-pencil-off
                              </v-icon>
                            </v-btn>
                          </template>
                          <span v-if="item.edit">{{ item.user }} can EDIT</span>
                          <span v-if="!item.edit">{{ item.user }} can't EDIT</span>
                        </v-tooltip>

                      </template>
                    </v-data-table>

                  </v-card>
                </v-tab-item>

                <v-tab-item
                >
                  <v-card flat>
                    <v-card-text>

                      <template>

                        <v-row class="py-2">
                          <v-col>
                            <v-switch
                              inset
                              label="City Manager Office"
                              color="secondary"
                              class="mt-0 pa-0"
                              hide-details
                            ></v-switch>
                            <p class="important--text font-italic pl-14 mb-0">Deactivated</p>
                          </v-col>
                          <v-col class="py-0 d-flex align-center text-caption">
                            Sed nec est sit amet magna facilisis mattis. Pellentesque efficitur metus urna. Duis in tortor nunc. Vivamus sit amet nisl ac ante maximus molestie. Proin sed diam quis magna tristique fringilla.
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-divider class="custom-divider"></v-divider>
                        </v-row>

                        <v-row class="py-2">
                          <v-col>
                            <v-switch
                              inset
                              label="City Secretary Office"
                              color="secondary"
                              class="mt-0 pa-0"
                              hide-details
                            ></v-switch>
                          </v-col>
                          <v-col class="py-0 d-flex align-center text-caption">
                            Suspendisse libero urna, scelerisque posuere ligula aliquet, condimentum elementum sem. Proin rutrum ac mauris eu feugiat.
                          </v-col>
                        </v-row>

                      </template>

                    </v-card-text>
                  </v-card>
                </v-tab-item>

              </v-tabs-items>

          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ItemSharePermissions',
  props: {
    item: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    tab: 0,
    headers: [
      { text: 'User', value: 'user' },
      { text: '', value: 'administrator' },
      { text: '', value: 'workgroup' },
      { text: '', value: 'view', width: '100px', align: 'center' },
      { text: '', value: 'edit', width: '100px', align: 'center' }
    ],
    staffSample: [
      { user: 'Noa Bender', owner: true, administrator: true, view: true, edit: true },
      { user: 'Charles Simon', owner: false, administrator: true, view: false, edit: false },
      { user: 'Vee Caron', owner: false, administrator: true, view: true, edit: false, workgroup: 'Name of Workgroup' },
      { user: 'Rico Reis', owner: false, administrator: false, view: true, edit: true },
      { user: 'Chad Edwards', owner: false, administrator: false, view: true, edit: false }
    ]
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    boardName () {
      return this.item.board?.name
    }
  }
})
</script>
