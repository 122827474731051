<template>

  <v-dialog
    width="600"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6">
        <span>Set item <span class="font-weight-black">{{ item.title }}</span></span>
        <span>of <span class="font-weight-black">{{  boardName }}</span> as private?</span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-actions class="d-flex align-center justify-center">
        <v-btn depressed width="130" @click="show=false" class="mr-2">No</v-btn>
        <v-btn depressed width="130" class="ml-2" color="secondary" @click="submitForm">Yes</v-btn>
      </v-card-actions>

    </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

function initialState () {
  return {
    valid: false,
    saving: false,
    form: {
      is_private: false
    }
  }
}

export default Vue.extend({
  name: 'ItemPrivate',
  props: {
    item: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => initialState(),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    boardName () {
      return this.item.board?.name
    }
  },
  methods: {
    resetModal () {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      Object.assign(this.$data, initialState())
      this.saving = false
    },
    submitForm () {
      const formPassedValidation = this.$refs.form.validate()
      if (formPassedValidation === false) {
        return false
      }
      this.form.is_private = true
      this.saving = true
      axios({
        method: 'put',
        url: process.env.VUE_APP_BACKEND_URL + '/api/items/' + this.item.id,
        data: this.form
      }).then(() => {
        this.resetModal()
        this.show = false
        this.$emit('reload', true)
      }).catch(error => {
        console.log('error', error)
      })
    }
  }
})
</script>
