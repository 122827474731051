It
<template>

  <v-dialog
    width="1100"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>New Item</span>
        <span>for <span class="font-weight-black">{{ board.name }}</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col class="d-flex align-center">
            <v-switch
              inset
              color="secondary"
              class="mt-0 pa-0 mr-8"
              label="In a Meeting?"
              hide-details
              v-model="inMeeting"
            >
            </v-switch>
            <span v-if="!inMeeting">
              <v-icon color="orange lighten-3">mdi-alert</v-icon> This item will be parked for future use
            </span>
          </v-col>
        </v-row>

        <v-row v-if="inMeeting">
          <v-col>
            <v-select
              label="Meeting"
              outlined
              small
              hide-details
              :items="meetingList"
              v-model="meetingSelected"
            ></v-select>
          </v-col>
        </v-row>

        <v-row v-if="inMeeting && meetingSelected">
          <v-col>
            <v-select
              label="Region"
              outlined
              small
              hide-details
              :items="regionList"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="d-flex mb-16">
          <v-col class="d-flex">
            <v-select
              label="Item Type"
              outlined
              small
              v-model="form.item_type_id"
              :items="itemTypes"
              :item-value="item => item.id"
              :item-text="item => item.type"
              :rules="rules.itemTypeRules"
            ></v-select>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="darkGrey"
                  size="18"
                  class="ml-3"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span>
              Explanation
            </span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex align-center">
            <span class="secondary--text text-body-1 text-uppercase font-weight-bold">
              Title
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-textarea
              outlined
              label="Title"
              v-model="form.title"
              :rules="rules.titleRules"
              :counter="1000"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col v-if="hasAlias">
            <v-text-field
              outlined
              label="Alias"
              v-model="form.alias"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="d-flex align-center">
            <v-switch
              inset
              color="secondary"
              class="mt-0 pa-0"
              label="Use alias"
              hide-details
              v-model="hasAlias"
            >
            </v-switch>
          </v-col>
        </v-row>

        <v-row class="d-flex mb-16">
          <v-col cols="10">
            <v-text-field
              outlined
              label="Tags"
              v-model="form.tags"
              :rules="rules.tagRules"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="d-flex" :class="!hasCoversheet? 'mb-16':'mb-0'">
          <v-col class="d-flex align-center">
            <v-switch
              inset
              color="secondary"
              class="mt-0 pa-0 mr-8 py-4"
              label="Use Cover Sheet"
              hide-details
              v-model="hasCoversheet"
            >
            </v-switch>
            <span v-if="!hasCoversheet">
              <v-icon color="orange lighten-3">mdi-alert</v-icon> Message about not using a Cover Sheet
            </span>
          </v-col>
          <v-col class="d-flex justify-end" v-if="hasCoversheet">
            <v-btn
              depressed
              text
              color="secondary"
              @click="useCoversheetTemplate = false"
              v-if="useCoversheetTemplate"
            >
              Want to upload a cover sheet?
            </v-btn>
            <v-btn
              depressed
              text
              color="secondary"
              @click="useCoversheetTemplate = true"
              v-if="!useCoversheetTemplate"
            >
              Want to use an automatic cover sheet?
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          v-if="hasCoversheet && useCoversheetTemplate"
          class="d-flex mb-16"
        >
          <v-col>
            <v-select
              label="Cover Sheet Template"
              outlined
              small
              v-model="form.cover_sheet_id"
              :items="coverSheets"
              :item-value="item => item.id"
              :item-text="item => item.name"
            ></v-select>
          </v-col>
        </v-row>

        <v-row
          v-if="hasCoversheet && !useCoversheetTemplate"
          class="d-flex mb-16"
        >
          <v-col>
            <v-card
              elevation="0"
              class="d-flex align-center justify-center"
              height="200"
              style="border: 1px dashed rgba(0,0,0,0.2);"
            >
              <v-icon color="lightGrey">mdi-cloud-upload</v-icon>
              <span class="lightGrey--text d-flex ml-4 text-caption text-uppercase">Click or drag/drop to add file</span>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="veryLightGrey pa-12 align-center flex-row d-flex">
            <v-switch
              inset
              color="secondary"
              class="mt-0 pa-0 mr-4"
              v-model="form.is_private"
              hide-details
            >
            </v-switch>
            <span class="text-body-1">
              Set this item as <span class="font-weight-black">PRIVATE</span>
            </span>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="darkGrey"
                  size="18"
                  class="ml-3"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span>
              Explanation
            </span>
            </v-tooltip>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Cancel</v-btn>
        <v-btn depressed width="130" color="secondary" @click="addItem">Add</v-btn>
      </v-card-actions>

    </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import ItemType from '@/models/ItemType'
import CoverSheet from '@/models/CoverSheet'
import axios from 'axios'

function initialState () {
  return {
    valid: false,
    saving: false,
    itemTypes: [],
    coverSheets: [],
    useLabel: false,
    inMeeting: false,
    hasCoversheet: false,
    useCoversheetTemplate: true,
    hasAlias: false,
    meetingSelected: null,
    meetingList: ['Name of Meeting 1', 'Name of Meeting 2', 'Name of Meeting 3'],
    regionList: ['Name of Region 1', 'Name of Region 2', 'Name of Region 3'],
    itemTypeList: ['Name of Item Type 1', 'Name of Item Type 2', 'Name of Item Type 3'],
    coverSheetList: ['Name of Cover Sheet 1', 'Name of Cover Sheet 2', 'Name of Cover Sheet 3'],
    form: {
      title: '',
      alias: '',
      item_type_id: '',
      cover_sheet_id: '',
      tags: '',
      is_private: false
    },
    rules: {
      itemTypeRules: [
        v => !!v || 'Item Type is required'
      ],
      titleRules: [
        v => !!v || 'Title is required',
        v => (v && v.length <= 1000) || 'Title must be less than 1000 characters'
      ],
      tagRules: [
        v => !!v || 'Tags are required'
      ]
      // coverSheetRules: [
      //   v => (!v && this.$data.hasCoversheet) || 'Cover Sheet is required'
      // ]
    }
  }
}

export default Vue.extend({
  name: 'ItemCreate',
  props: {
    item: {},
    board: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  data: function () {
    return initialState()
  },
  computed: {
    aTitle: {
      get () {
        return this.form.title
      },
      set (value) {
        this.form.title = value
      }
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    resetModal () {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      Object.assign(this.$data, initialState())
      this.saving = false
    },
    addItem () {
      const formPassedValidation = this.$refs.form.validate()
      if (formPassedValidation === false) {
        return false
      }
      this.saving = true
      if (!this.hasAlias) {
        this.form.alias = ''
      }
      if (!this.hasCoversheet) {
        this.form.cover_sheet_id = ''
      }
      if (!this.isMeeting) {
        this.form.is_parked = true
      }
      this.form.board_id = this.board.id

      // const axiosAction = (this.modeType === 'create' || this.modeType === 'duplicate') ? 'post' : 'put'
      // const axiosUrl = (this.modeType === 'edit') ? '/api/cover-sheets/' + this.coverSheet.id : '/api/cover-sheets'
      const axiosAction = 'post'
      const axiosUrl = '/api/items'

      axios({
        method: axiosAction,
        url: process.env.VUE_APP_BACKEND_URL + axiosUrl,
        data: this.form
      }).then(() => {
        console.log('success')
        this.resetModal()
        this.show = false
        this.$emit('reload', true)
      }).catch(error => {
        console.log('error', error)
        // console.log(error.response.data)
        // console.log(error.response.status)
        // console.log(error.response.headers)
      })
    },
    getItemTypesAndCoverSheets () {
      ItemType
        .where('client_id', this.$store.state.client.activeClient.clientID)
        .where('board_id', this.board.id)
        .where('status', 'active')
        .get()
        .then(response => {
          this.itemTypes = response.data
          const itemTypeIds = this.itemTypes.map(item => item.id)
          console.log('item type ids', itemTypeIds)
          CoverSheet
            .whereIn('item_type_id', itemTypeIds)
            .get()
            .then(coverSheetResponse => {
              this.coverSheets = coverSheetResponse.data
              console.log('this.coverSheets', this.coverSheets)
            })
        })
    }
  },
  watch: {
    show: function (value) {
      // Modal Displayed
      if (value) {
        this.getItemTypesAndCoverSheets()
      }
      // if (value === true && this.modeType === 'edit') {
      //   this.form.name = this.coverSheet.name
      //   this.form.description = this.coverSheet.description
      // } else if (value === true && this.modeType === 'create') {
      //   this.resetModal()
      // }
    }
  }
})
</script>
