<template>

  <v-dialog
    width="1100"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Change Item Type of <span class="font-weight-black">{{ item.title }}</span> for <span class="font-weight-black">{{ boardName }}</span></span>
      </v-card-title>
      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text :class="sections.length > 0 > 0 ? 'mb-8' : ''">

        <v-row class="d-flex" :class="sections.length > 0 ? 'mb-8' : ''">
          <v-col cols="6">

            <v-select
              label="Item Type"
              outlined
              small
              v-model="form.item_type_id"
              :items="itemTypes"
              :item-value="item => item.id"
              :item-text="item => item.type"
              :rules="rules.itemTypeRules"
            ></v-select>

          </v-col>
        </v-row>

        <div class="d-flex justify-space-between">
          <span style="width: 47%" class="d-flex flex-column" v-if="sections.length > 0">
            <v-row>
              <v-col class="d-flex mb-4 secondary--text text-uppercase font-weight-bold text-body-1">
                Current Item Type
              </v-col>
            </v-row>
          </span>
          <span style="width: 47%" class="d-flex flex-column" v-if="newSections.length > 0">
            <v-row>
              <v-col class="d-flex mb-4 secondary--text text-uppercase font-weight-bold text-body-1">
                New Item Type
              </v-col>
            </v-row>
          </span>
        </div>
        <div class="d-flex justify-space-between" v-if="sections.length > 0">
          <span style="width: 47%" class="d-flex flex-column">

            <v-card
              elevation="3"
            >

              <v-list class="pa-0 custom-list-separator">
                <v-list-item-group>
                  <draggable
                    v-model="filteredSections"
                    @start="startingDrag()"
                    @end="endingDrag()"
                    :sort="false"
                    :options="{ group:{ name:'newSubSections', pull: 'clone', put: 'newSubSections' }}"
                    v-bind="dragOptions">
                  <v-list-item
                    v-for="(item,n) in filteredSections"
                    :key="n"
                    class="pa-0 custom-list-no-highlight"
                  >
                      <v-row>
                        <v-col class="d-flex darkGrey--text justify-space-between flex-column py-0">
                        <v-row>
                          <v-col class="d-flex justify-space-between align-center py-6">
                            <span class="pl-4">
                              <v-icon v-if="item.original_section.component_type === 'textarea'" class="d-flex mr-3" color="secondary" size="22">mdi-text-box-outline</v-icon>
                              <v-icon v-if="item.original_section.component_type === 'text'" class="d-flex mr-3" color="secondary" size="22">mdi-cursor-text</v-icon>
                              <v-icon v-if="item.original_section.component_type === 'checkbox'" class="d-flex mr-3" color="secondary" size="22">mdi-check-box-outline</v-icon>
                              <v-icon v-if="item.original_section.component_type === 'radio'" class="d-flex mr-3" color="secondary" size="22">mdi-radiobox-marked</v-icon>
                              <v-icon v-if="item.original_section.component_type === 'select'" class="d-flex mr-3" color="secondary" size="22">mdi-form-dropdown</v-icon>
                            </span>
                            <span class="d-flex align-start flex-column">
                              <span class="text-uppercase secondary--text text-caption">{{ item.original_section.component_type }}</span>
                              <span>{{ item.original_section.name }}</span>
                            </span>
                            <v-spacer></v-spacer>
                            <span class="pr-4" v-if="item.content !== null">
                              <v-menu
                                right
                                top
                                open-on-click
                                close-on-click
                                attach
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    depressed
                                  >
                                    <v-icon>mdi-eye-outline</v-icon>
                                  </v-btn>
                                </template>
                                <v-list
                                  width="300"
                                  max-height="250"
                                  class="py-6 px-4"
                                  style="overflow-y: auto; overflow-x: hidden"
                                >
                                  <v-list-item
                                    inactive
                                  >
                                    <span
                                      class="text-caption"
                                    >
                                      {{ item.content }}
                                    </span>
<!--                                    <span-->
<!--                                      v-if="item.original_section.component_type === 'select'"-->
<!--                                      class="d-flex flex-column"-->
<!--                                      style="width:100%"-->
<!--                                    >-->
<!--                                      <span v-for="(options,op) in JSON.parse(item.possible_values)" :key="'op'+op" class="py-3" :style="options.selected ? 'background-color: #BEBEBE; width:100%': 'width:100%'">-->
<!--                                        <span-->
<!--                                          :class="item.value == options.label? 'secondary&#45;&#45;text font-weight-bold text-uppercase pl-2':'pl-2' "-->
<!--                                        >-->
<!--                                          {{ options.label }}-->
<!--                                        </span>-->
<!--                                      </span>-->
<!--                                    </span>-->
<!--                                    <v-radio-group-->
<!--                                      v-if="item.component_type === 'radio'"-->
<!--                                      v-model="item.name"-->
<!--                                      hide-details-->
<!--                                      class="pa-0 ma-0"-->
<!--                                    >-->
<!--                                      <v-radio-->
<!--                                        v-for="(radioOption,opt) in JSON.parse(item.possible_values)"-->
<!--                                        :key="opt"-->
<!--                                        :label="radioOption.label"-->
<!--                                        :value="radioOption.value"-->
<!--                                        class="pa-0"-->
<!--                                        :style="radioOption.checked ? 'background-color: #BEBEBE; width:100%': 'width:100%'"-->
<!--                                        disabled-->
<!--                                      ></v-radio>-->
<!--                                    </v-radio-group>-->
<!--                                    <v-checkbox-->
<!--                                      v-if="item.component_type === 'checkbox'"-->
<!--                                      class="pa-0 ma-0"-->
<!--                                      :label="JSON.parse(item.possible_values).label"-->
<!--                                      hide-details-->
<!--                                      v-model="JSON.parse(item.possible_values).value"-->
<!--                                      disabled-->
<!--                                    ></v-checkbox>-->
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </span>
                          </v-col>
                        </v-row>
                      </v-col>
                      </v-row>
                  </v-list-item>
                  </draggable>
                </v-list-item-group>
              </v-list>

            </v-card>

          </span>
          <span style="width: 47%" class="d-flex flex-column">

            <v-card
              elevation="3"
            >

              <v-list
                class="pa-0 custom-list-separator"
              >
                <v-list-item-group>
                    <v-list-item
                      v-for="(item,n) in newSections"
                      :key="n"
                      class="pa-0 custom-list-no-highlight"
                    >
                    <v-row>
                      <v-col class="d-flex darkGrey--text justify-space-between flex-column py-0">
                        <v-row>
                          <v-col class="d-flex justify-space-between align-center py-6">
                            <span class="pl-4">
                              <v-icon v-if="item.component_type === 'textarea'" class="d-flex mr-3" color="secondary" size="22">mdi-text-box-outline</v-icon>
                              <v-icon v-if="item.component_type === 'text'" class="d-flex mr-3" color="secondary" size="22">mdi-cursor-text</v-icon>
                              <v-icon v-if="item.component_type === 'checkbox'" class="d-flex mr-3" color="secondary" size="22">mdi-check-box-outline</v-icon>
                              <v-icon v-if="item.component_type === 'radio'" class="d-flex mr-3" color="secondary" size="22">mdi-radiobox-marked</v-icon>
                              <v-icon v-if="item.component_type === 'select'" class="d-flex mr-3" color="secondary" size="22">mdi-form-dropdown</v-icon>
                            </span>
                            <span class="d-flex align-start flex-column">
                              <span class="text-uppercase secondary--text text-caption">{{ item.component_type }}</span>
                              <span class="text-uppercase">{{ item.name }}</span>
                            </span>
                            <v-spacer></v-spacer>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="pa-8 pt-0">
                             <draggable
                               v-model="item.newSubSections"
                               @start="startingDrag()"
                               @end="endingDragNewSubSections()"
                               :sort="false"
                               :key="Math.random()"
                               :options="{group:{ name:'newSubSections'}}"
                               @change="onChangeDraggableNewSubsections($event, n)"
                               v-bind="dragOptions">
                            <span
                              v-if="Object.keys(newSections[n].newSubSections).length === 0"
                              class="d-flex py-8 justify-center text-uppercase lightGrey--text"
                              style="border: 1px dashed rgba(0,0,0,0.15);"
                            >
                              Drag and Drop here
                            </span>
                            <v-card
                              color="veryLightGrey"
                              class="mb-5"
                              elevation="0"
                              v-if="item.newSubSections.length > 0"
                            >
                              <v-row>
                                <v-col class="d-flex justify-space-between align-center">
                                  <span class="pl-4">
                                    <v-icon v-if="item.newSubSections[0].component_type === 'textarea'" class="d-flex mr-3" color="grey" size="16">mdi-text-box-outline</v-icon>
                                    <v-icon v-if="item.newSubSections[0].component_type === 'text'" class="d-flex mr-3" color="grey" size="16">mdi-cursor-text</v-icon>
                                    <v-icon v-if="item.newSubSections[0].component_type === 'checkbox'" class="d-flex mr-3" color="grey" size="16">mdi-check-box-outline</v-icon>
                                    <v-icon v-if="item.newSubSections[0].component_type === 'radio'" class="d-flex mr-3" color="grey" size="16">mdi-radiobox-marked</v-icon>
                                    <v-icon v-if="item.newSubSections[0].component_type === 'select'" class="d-flex mr-3" color="grey" size="16">mdi-form-dropdown</v-icon>
                                  </span>
                                  <span class="d-flex align-start flex-column">
                                    <span class="text-caption">{{ item.newSubSections[0].original_section.name }}</span>
                                  </span>
                                  <v-spacer></v-spacer>
                                  <span class="pr-4">
                                    <v-menu
                                      left
                                      top
                                      open-on-click
                                      close-on-click
                                      attach
                                      v-if="item.newSubSections[0].content"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          v-bind="attrs"
                                          v-on="on"
                                          icon
                                          depressed
                                        >
                                          <v-icon>mdi-eye-outline</v-icon>
                                        </v-btn>
                                      </template>
                                      <v-list
                                        width="300"
                                        max-height="250"
                                        class="py-6 px-4"
                                        style="overflow-y: auto; overflow-x: hidden"
                                      >
                                        <v-list-item
                                          inactive
                                          active-class=""
                                        >
                                          <span
                                            class="text-caption"
                                          >
                                            {{ item.newSubSections[0].content }}
                                          </span>
                                        </v-list-item>
                                      </v-list>
                                    </v-menu>
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          dark
                                          v-bind="attrs"
                                          v-on="on"
                                          color="darkGrey"
                                          @click="removeSubSection(item, n)"
                                          icon
                                        >
                                          <v-icon>mdi-border-none-variant</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Empty this data section</span>
                                    </v-tooltip>
                                  </span>
                                </v-col>
                              </v-row>
                            </v-card>
                            </draggable>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                  </v-list-item>
                </v-list-item-group>
              </v-list>

            </v-card>

          </span>
        </div>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="updateItemType">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import ItemType from '@/models/ItemType'
import axios from 'axios'
import ItemTypeSection from '@/models/ItemTypeSection'
import draggable from 'vuedraggable'

function initialState () {
  return {
    saving: false,
    form: {
      item_type_id: ''
    },
    sections: [],
    newSections: [],
    newSubSections: [],
    rules: {
      itemTypeRules: [
        v => !!v || 'Item Type is required'
      ]
    },
    itemTypes: [],
    model1: 0,
    model2: 0,
    itemTypeSelected: 0,
    itemTypesSample: [
      { label: 'Item Type 1', value: 1 },
      { label: 'Item Type 2', value: 2 },
      { label: 'Item Type 3', value: 3 },
      { label: 'Item Type 4', value: 4 }
    ],
    coverSheetSource: [
      {
        type: 'textarea',
        title: 'Name of Data Section Component 1',
        value: 'Integer aliquet tortor at lobortis malesuada. Pellentesque eleifend metus in vulputate sollicitudin. In aliquam risus at lectus semper, id vestibulum leo euismod. Aenean lacinia scelerisque orci.'
      },
      {
        type: 'input',
        title: 'Name of Data Section Component 2',
        value: 'Charles Simon'
      },
      {
        type: 'radio',
        title: 'Name of Data Section Component 3',
        value: 'No',
        possible_values: [
          { label: 'Yes' },
          { label: 'No' }
        ]
      },
      {
        type: 'textarea',
        title: 'Name of Data Section Component 4',
        value: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea.'
      },
      {
        type: 'textarea',
        title: 'Name of Data Section Component 5',
        value: 'Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea..'
      },
      {
        type: 'checkbox',
        title: 'Name of Data Section Component 6',
        value: true,
        label: 'Sample label'
      },
      {
        type: 'select',
        title: 'Name of Data Section Component 7',
        value: 'Region 3',
        possible_values: [
          { label: 'Region 1' },
          { label: 'Region 2' },
          { label: 'Region 3' },
          { label: 'Region 4' },
          { label: 'Region 5' }
        ]
      }
    ],
    coverSheetDestination: [
      {
        empty: true,
        component_type: 'textarea',
        title: 'Name of Data Section Component 1',
        value: 'Integer aliquet tortor at lobortis malesuada. Pellentesque eleifend metus in vulputate sollicitudin. In aliquam risus at lectus semper, id vestibulum leo euismod. Aenean lacinia Integer aliquet tortor at lobortis malesuada. Pellentesque eleifend metus in vulputate sollicitudin. In aliquam risus at lectus semper, id vestibulum leo euismod. Aenean lacinia Integer aliquet tortor at lobortis malesuada. Pellentesque eleifend metus in vulputate sollicitudin. In aliquam risus at lectus semper, id vestibulum leo euismod. Aenean lacinia scelerisque orci.'
      },
      {
        component_type: 'text',
        title: 'Name of Data Section Component 2',
        value: 'Charles Simon'
      },
      {
        component_type: 'radio',
        title: 'Name of Data Section Component 3',
        value: 'No',
        possible_values: [
          { label: 'Yes' },
          { label: 'No' }
        ]
      },
      {
        component_type: 'checkbox',
        title: 'Name of Data Section Component 6',
        value: true,
        label: 'Sample label'
      },
      {
        component_type: 'select',
        title: 'Name of Data Section Component 7',
        value: 'Region 3',
        possible_values: [
          { label: 'Region 1' },
          { label: 'Region 2' },
          { label: 'Region 3' },
          { label: 'Region 4' },
          { label: 'Region 5' }
        ]
      }
    ],
    filteredSections: []
  }
}

export default Vue.extend({
  name: 'ItemType',
  props: {
    item: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  components: {
    draggable
  },
  data: () => initialState(),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    boardName () {
      return this.item.board?.name
    },
    dragOptions () {
      return {
        // handle: '.handle',
        animation: 500,
        ghostClass: 'ghostClass',
        dragClass: 'dragClass',
        chosenClass: 'chosenClass'
      }
    }
  },
  methods: {
    removeSubSection (item, n) {
      const elmIndex = this.sections.findIndex(section => section.id === item.newSubSections[0].id)
      this.$set(this.sections[elmIndex], 'showInList', true)
      this.filteredSections = this.sections.filter(section => section.showInList === true)
      item.newSubSections = []
      this.newSections[n] = item
      this.$forceUpdate()
    },
    getItemTypes () {
      ItemType
        .where('client_id', this.$store.state.client.activeClient.clientID)
        .where('board_id', this.item.board_id)
        .where('status', 'active')
        .get()
        .then(response => {
          this.itemTypes = response.data
        })
    },
    getSectionsForCurrentItemType () {
      this.loading = true
      axios({
        method: 'get',
        url: process.env.VUE_APP_BACKEND_URL + '/api/items/getSectionsBasedOnItem/' + this.item.id
      }).then((response) => {
        this.sections = response.data
        this.sections.forEach(section => {
          section.showInList = true
        })
        this.filteredSections = this.sections.filter(section => section.showInList === true)
        this.loading = false
      })
      // ItemTypeSection
      //   .where('item_type_id', this.item.item_type_id)
      //   .get()
      //   .then(response => {
      //     this.sections = response.data
      //     this.sections.forEach(elm => {
      //       elm.active = false
      //       elm.pending = false
      //       elm.paused = false
      //       elm.reserved = false
      //       elm[elm.status] = true
      //
      //       if (elm.status === 'inactive') {
      //         elm.paused = true
      //       }
      //
      //       elm.coversheets = 1
      //       elm.sections = 1
      //     })
      //     this.loading = false
      //   })
    },
    getSectionNewItemType () {
      ItemTypeSection
        .where('item_type_id', this.form.item_type_id)
        .get()
        .then(response => {
          this.newSections = response.data
          this.newSections.forEach(elm => {
            elm.newSubSections = []
          })
          this.newSubSections = this.newSections
          this.loading = false
        })
    },
    updateItemType () {
      this.saving = true
      this.form.newSections = this.newSubSections

      axios({
        method: 'put',
        url: process.env.VUE_APP_BACKEND_URL + '/api/items/' + this.item.id,
        data: this.form
      }).then(() => {
        this.saving = false
        this.show = false
        Object.assign(this.$data, initialState())
        this.$emit('reload', true)
      })
    },
    startingDrag () {
      this.dragging = true
    },
    onChangeDraggableNewSubsections (event, newSectionIndex) {
      if (event.added === undefined) return false
      const hadToCleanExtraFields = this.cleanExtraSubSections()
      if (hadToCleanExtraFields) return false
      const newSection = this.newSections[newSectionIndex]
      const isAllowed = this.isNewSubsectionAllowed(newSection, event.added.element)
      if (!isAllowed) {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: 'Whoops! You cannot map the ' + event.added.element.original_section.component_type + ' item to the ' + newSection.component_type + ' item'
        })
        this.newSections[newSectionIndex].newSubSections = []
      } else {
        const elmIndex = this.sections.findIndex(section => section.id === event.added.element.id)
        this.$set(this.sections[elmIndex], 'showInList', false)
        this.filteredSections = this.sections.filter(section => section.showInList === true)
        this.$forceUpdate()
      }
      this.$forceUpdate()
    },
    isNewSubsectionAllowed (original, newElement) {
      const originalComponentType = original.component_type
      const newComponentType = newElement.original_section.component_type
      const textAllowedFields = ['text', 'textarea', 'select']
      const textAreaAllowedFields = ['text', 'textarea', 'select']
      const selectAllowedFields = ['text', 'textarea', 'select']
      const radioAllowedFields = ['radio', 'checkbox']
      const checkboxAllowedFields = ['radio', 'checkbox']

      switch (originalComponentType) {
        case 'text':
          return textAllowedFields.includes(newComponentType)
        case 'textarea':
          return textAreaAllowedFields.includes(newComponentType)
        case 'select':
          return selectAllowedFields.includes(newComponentType)
        case 'radio':
          return radioAllowedFields.includes(newComponentType)
        case 'checkbox':
          return checkboxAllowedFields.includes(newComponentType)
      }
      return false
    },
    cleanExtraSubSections () {
      let hadToCleanExtraFields = false
      this.newSubSections.forEach(section => {
        if (section.newSubSections.length > 1) {
          hadToCleanExtraFields = true
          section.newSubSections = [section.newSubSections[0]]
        }
      })
      return hadToCleanExtraFields
    },
    endingDrag () {
      this.dragging = false
    },
    endingDragNewSubSections () {
      this.dragging = false
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.form.item_type_id = this.item.item_type_id
        this.getSectionsForCurrentItemType()

        if (this.itemTypes.length === 0) {
          this.getItemTypes()
        }
      }
    },
    form: {
      handler () {
        if (this.form.item_type_id && this.item.item_type_id !== this.form.item_type_id) {
          this.getSectionNewItemType()
        } else {
          this.newSections = []
          this.newSubSections = []
        }
      },
      deep: true
    }
  }
})
</script>
