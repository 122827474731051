<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Conversations and Notes of <span class="font-weight-black">{{ item.title }}</span></span>
        <span>for <span class="font-weight-black">{{ boardName }}</span></span>
      </v-card-title>
      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-tabs
          vertical
          color="secondary"
          v-model="tab"
        >
          <v-tab class="mr-8 d-flex justify-start">
            <span class="d-flex align-center">
              <v-icon class="d-flex mr-3">mdi-message-text</v-icon>
              Conversations
            </span>
          </v-tab>
          <v-tab class="mr-8 d-flex justify-start">
            <span class="d-flex align-center">
              <v-icon class="d-flex mr-3">mdi-pin</v-icon>
              Notes
            </span>
          </v-tab>

          <v-tabs-items v-model="tab">

            <v-tab-item>
              <v-card flat>
                <v-card-text class="pt-0">

                  <v-row>
                    <v-col>
                      <v-checkbox
                        color="secondary"
                        label="Show only my comments"
                        v-model="showOnlyMyComments"
                        hide-details
                        class="custom-checkbox-small pa-0 ma-0"
                      ></v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row class="d-flex mb-8 mt-4">
                    <v-col>
                      <v-text-field
                        outlined
                        label="Comment"
                        clearable
                        v-model="conversationValue"
                        @focus="addConversationFocus=true"
                        @blur="addConversationFocus=false"
                      >
                        <template v-slot:append>
                          <v-icon
                            v-if="!addConversationFocus"
                            color="lightGrey"
                          >
                            mdi-send
                          </v-icon>
                          <v-icon
                            v-else
                            color="secondary"
                            @click="addConversation"
                          >
                            mdi-send
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-card
                    v-for="(item,n) in filteredComments"
                    :key="n"
                    class="d-flex mb-6"
                    elevation="0"
                    :color="checkIfOwner(item) ? 'secondary' : 'veryLightGrey'"
                    :class="isDeleted(item) ? 'opacity-high':''"
                  >
                    <v-card-text>
                      <v-row>
                        <v-col class="py-0">
                          <span :class="checkIfOwner(item) ? 'white--text' : 'darkGrey--text'" class="d-flex flex-column">
                            <span class="d-flex align-center justify-space-between" :class="isDeleted(item) > 0 ? 'mb-0':'mb-3'">
                              <span class="font-weight-bold">
                                <v-chip
                                  class="text-uppercase text-caption mr-2"
                                  small
                                  v-if="isDeleted(item)"
                                >
                                  Message Deleted
                                </v-chip>
                                {{ checkIfOwner(item) ? 'You' : getUsersName(item) }}
                              </span>
                              <span class="d-flex align-center">
                                <span class="text-right text-caption" :class="checkIfOwner(item)? 'white--text' : 'darkGrey--text'">{{ itemDate(item) }}</span>
                                <span class="text-right text-caption" :class="checkIfOwner(item)? 'white--text' : 'darkGrey--text'">{{ itemTime(item) }}</span>
                                <span class="d-flex justify-end" v-if="checkIfOwner(item) && !isDeleted(item)">
                                  <v-btn
                                    icon
                                    size="24"
                                  >
                                    <v-icon
                                      size="18"
                                      :color="checkIfOwner(item) ? 'white' : 'darkGrey'"
                                      @click="deleteConversation(item)"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </v-btn>
                                </span>
                              </span>
                            </span>
                            <span v-if="!isDeleted(item)">
                              {{ item.comment }}
                            </span>
                          </span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-text class="pt-0">

                  <v-row>
                    <v-col>
                      <v-checkbox
                        color="secondary"
                        label="Show only important"
                        v-model="showOnlyImportantNotes"
                        hide-details
                        class="custom-checkbox-small pa-0 ma-0"
                      ></v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row class="d-flex mb-8 mt-4">
                    <v-col>
                      <v-text-field
                        outlined
                        label="New action label"
                        clearable
                        v-model="noteValue"
                        @focus="addNoteFocus=true"
                        @blur="addNoteFocus=false"
                      >
                        <template v-slot:prepend-inner>
                          <v-icon
                            v-if="!addNoteFocus"
                            color="lightGrey"
                          >
                            mdi-alert-box
                          </v-icon>
                          <v-icon
                            v-else
                            :color="noteImportant? 'orange lighten-2' : 'lightGrey'"
                            @click="noteImportant = !noteImportant"
                          >
                            mdi-alert-box
                          </v-icon>
                        </template>
                        <template v-slot:append>
                          <v-icon
                            v-if="!addNoteFocus"
                            color="lightGrey"
                          >
                            mdi-plus-circle
                          </v-icon>
                          <v-icon
                            v-else
                            color="secondary"
                            @click="addNote(noteValue)"
                          >
                            mdi-plus-circle
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-card
                    v-for="(item,n) in filteredNotes"
                    :key="n"
                    class="d-flex mb-6"
                    elevation="0"
                    :color="item.important? 'secondary' : 'veryLightGrey'"
                    :class="isDeleted(item) ? 'opacity-high':''"
                  >
                    <v-card-text>
                      <v-row>
                        <v-col cols="1" class="py-0">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                @click="toggleImportant(item)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon v-if="item.important" color="white">mdi-alert-box</v-icon>
                                <v-icon v-if="!item.important" color="lightGrey">mdi-alert-box</v-icon>
                              </v-btn>
                            </template>
                            <span v-if="!item.important">
                              Not important
                            </span>
                            <span v-if="item.important">
                              Important
                            </span>
                          </v-tooltip>
                        </v-col>
                        <v-col class="py-0">
                          <span class="d-flex flex-column darkGrey--text">
                            <span class="d-flex align-center justify-space-between" :class="isDeleted(item) > 0 ? 'mb-0':'mb-3'">
                              <span class="font-weight-bold">
                                <v-chip
                                  class="text-uppercase text-caption mr-2"
                                  small
                                  v-if="isDeleted(item)"
                                >
                                  Note Deleted
                                </v-chip>
                                {{ checkIfOwner(item) ? 'You' : getUsersName(item) }}
                              </span>
                            </span>
                           </span>
                          <span :class="item.important? 'white--text' : 'darkGrey--text'" v-if="!isDeleted(item)">
                            {{ item.content }}
                          </span>
                        </v-col>
                        <v-col cols="3" class="d-flex flex-column py-0">
                          <span class="text-right text-caption" :class="item.important? 'white--text' : 'darkGrey--text'">{{ itemDate(item) }}</span>
                          <span class="text-right text-caption" :class="item.important? 'white--text' : 'darkGrey--text'">{{ itemTime(item) }}</span>
                          <span class="d-flex justify-end">

                            <v-menu
                              offset-y
                              top
                              left
                              open-on-click
                              :close-on-content-click="false"
                              v-if="!isDeleted(item)"
                            >
                              <template v-slot:activator="{ on, attrs }" v-if="checkIfOwner(item)">
                                <v-btn
                                  color="darkGrey"
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  size="24"
                                >
                                  <v-icon
                                    size="18"
                                    :color="item.important? 'white' : 'darkGrey'"
                                  >
                                    mdi-pencil
                                  </v-icon>
                                </v-btn>
                              </template>
                              <v-list width="400">
                                <v-list-item>
                                  <v-textarea v-model="item.content">
                                    <template v-slot:append>
                                      <v-icon
                                        v-if="!addNoteFocus"
                                        color="secondary"
                                        @click="updateNoteContent(item)"
                                      >
                                        mdi-send
                                      </v-icon>
                                    </template>
                                  </v-textarea>
                                </v-list-item>
                              </v-list>
                            </v-menu>

                            <v-btn
                              icon
                              size="24"
                              v-if="!isDeleted(item) && checkIfOwner(item)"
                            >
                              <v-icon
                                size="18"
                                :color="item.important? 'white' : 'darkGrey'"
                                @click="deleteNote(item)"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>

                          </span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                </v-card-text>
              </v-card>
            </v-tab-item>

          </v-tabs-items>

        </v-tabs>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { mapGetters } from 'vuex'

function initialState () {
  return {
    comments: [],
    showOnlyMyComments: false,
    showOnlyImportantNotes: false,
    notes: [],
    tab: 0,
    addNoteFocus: false,
    noteDelete: true,
    noteImportant: false,
    noteValue: '',
    addConversationFocus: false,
    conversationDelete: true,
    conversationValue: '',
    conversationSample: [
      {
        comment: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores',
        owner: true,
        user: 'Rico Reis',
        deleted: false,
        date: 'Fri, Nov 30, 2018',
        time: '1:27 PM'
      },
      {
        comment: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores',
        owner: false,
        user: 'Chad Edwards',
        deleted: false,
        date: 'Fri, Nov 30, 2018',
        time: '1:27 PM'
      },
      {
        comment: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores',
        owner: false,
        user: 'Charles Simon',
        deleted: false,
        date: 'Fri, Nov 30, 2018',
        time: '1:27 PM'
      },
      {
        comment: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores',
        owner: false,
        user: 'Vee Caron',
        deleted: true,
        date: 'Fri, Nov 30, 2018',
        time: '1:27 PM'
      },
      {
        comment: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores',
        owner: true,
        user: 'Rico Reis',
        deleted: true,
        date: 'Fri, Nov 30, 2018',
        time: '1:27 PM'
      },
      {
        comment: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores',
        owner: true,
        user: 'Rico Reis',
        deleted: false,
        date: 'Fri, Nov 30, 2018',
        time: '1:27 PM'
      },
      {
        comment: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores',
        owner: false,
        user: 'Chad Edwards',
        deleted: false,
        date: 'Fri, Nov 30, 2018',
        time: '1:27 PM'
      },
      {
        comment: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores',
        owner: false,
        user: 'Charles Simon',
        deleted: false,
        date: 'Fri, Nov 30, 2018',
        time: '1:27 PM'
      }
    ],
    notesSample: [
      {
        note: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores',
        important: true,
        date: 'Fri, Nov 30, 2018',
        time: '1:27 PM'
      },
      {
        note: 'Sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam',
        important: true,
        date: 'Fri, Nov 30, 2018',
        time: '1:27 PM'
      },
      {
        note: 'Diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam',
        important: false,
        date: 'Fri, Nov 30, 2018',
        time: '1:27 PM'
      }
    ]
  }
}

export default Vue.extend({
  name: 'ItemConversationsNotes',
  props: {
    item: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => initialState(),
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    boardName () {
      return this.item.board?.name
    },
    filteredComments () {
      if (this.showOnlyMyComments) {
        return this.comments.filter(comment => comment.user_id === this.user.id)
      }
      return this.comments
    },
    filteredNotes () {
      if (this.showOnlyImportantNotes) {
        return this.notes.filter(note => note.important)
      }
      return this.notes
    }
  },
  methods: {
    addNote () {
      const form = {
        item_id: this.item.id,
        content: this.noteValue,
        important: this.noteImportant
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + '/api/item-note/create', form)
        .then((res) => {
          this.getNotesApi()
        })
      this.noteValue = ''
      this.noteImportant = false
    },
    deleteNote (item) {
      axios.delete(process.env.VUE_APP_BACKEND_URL + '/api/item-note/' + item.id + '/delete')
        .then((res) => {
          this.getNotesApi()
        })
    },
    addConversation () {
      const form = {
        item_id: this.item.id,
        comment: this.conversationValue
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + '/api/item-comment/create', form)
        .then((res) => {
          this.getCommentsApi()
        })
      this.conversationValue = ''
    },
    deleteConversation (item) {
      axios.delete(process.env.VUE_APP_BACKEND_URL + '/api/item-comment/' + item.id + '/delete')
        .then((res) => {
          this.getCommentsApi()
        })
    },
    isDeleted (item) {
      if (item.deleted_at !== undefined && item.deleted_at?.length > 0) {
        return true
      }
      return false
    },
    getUsersName (item) {
      return item.user?.first_name + ' ' + item.user?.last_name
    },
    checkIfOwner (item) {
      return item.user_id === this.user.id
    },
    itemDate (item) {
      const date = new Date(item.created_at)
      return date.toDateString()
    },
    itemTime (item) {
      const date = new Date(item.created_at)
      return date.toLocaleTimeString()
    },
    updateNoteContent (item) {
      const data = {
        content: item.content
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + '/api/item-note/' + item.id + '/update', data)
    },
    toggleImportant (item) {
      item.important = !item.important
      const data = {
        important: item.important
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + '/api/item-note/' + item.id + '/update', data)
    },
    getNotesApi () {
      // Get Notes
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/items/' + this.item.id + '/get-notes/')
        .then((res) => {
          this.notes = res.data
        })
    },
    getCommentsApi () {
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/items/' + this.item.id + '/get-comments/')
        .then((res) => {
          this.comments = res.data
        })
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.getCommentsApi()
        this.getNotesApi()
      }
    }
  }
})
</script>
