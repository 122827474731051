<template>

  <v-dialog
    width="1100"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline mb-6 align-start" v-if="!editStructure">
        <span class="d-flex flex-column">
          <span>Edit Cover Sheet Label of <span class="font-weight-black">{{ item.title }}</span> of <span class="font-weight-black">{{ boardName }}</span></span>
        </span>
        <v-spacer></v-spacer>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-tabs
        v-model="tab"
        centered
        color="secondary"
        background-color="transparent"
        class="custom-tab"
        v-if="!editStructure"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-0">
            <span class="d-flex align-center">
              Draft
            </span>
        </v-tab>
        <v-tab href="#tab-1">
            <span class="d-flex align-center">
              Data Sections
            </span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="transparent" v-if="!editStructure">

        <v-tab-item value="tab-0">

          <v-card-text class="pa-3 mb-6 overflow-auto">

            <v-row class="d-flex justify-center mt-4 mb-8">
              <v-spacer></v-spacer>
              <v-col class="d-flex align-center">
                <v-switch
                  inset
                  label="Highlight editable content"
                  color="secondary"
                  v-model="highlightEditable"
                  class="mt-0"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col class="d-flex align-center justify-end">
                <v-btn
                  depressed
                  color="secondary"
                  @click="editStructure = true"
                >
                  <v-icon class="d-flex mr-3">mdi-table</v-icon> Edit Structure
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>

            <div class="letter-wrapper">
              <v-card
                elevation="8"
                class="d-flex flex-column rounded-0 letter-paper"
                style="padding: 0.5in"
              >

                <span style="display: flex; width: 100%; padding-bottom: 16px; padding-top: 6px;">
                  <span style="width: 2in; float: left;">
                    <img src="@/assets/logo-sample.png">
                  </span>
                  <span style="width: 5.5in; font-weight: bold; font-size: 32px; display: flex; align-items: center; color: #02406A">
                    Staff Memo
                  </span>
                </span>

                <!-- TEXTAREA SAMPLE -->
                <span style="display: flex; width: 100%; padding-bottom: 16px; padding-top: 6px;">
                  <span style="width: 2in; font-weight: bold; float: left;">
                    Title
                  </span>
                  <span style="width: 5.5in; float: left;">
                    <span class="editable-content" :class="highlightEditable ? 'highlighted' : ''">
                      <div class="editable-button">
                        <v-menu
                          offset-y
                          top
                          :close-on-content-click="false"
                          attach
                          min-width="350"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              block
                              style="height: 100%;"
                              color="transparent"
                              depressed
                              icon
                            >
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item
                            >
                              <v-textarea
                                v-if="coverSheetSample[0].type==='textarea'"
                                hide-details
                                v-model="coverSheetSample[0].value"
                              >
                              </v-textarea>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                      {{ coverSheetSample[0].value }}
                    </span>
                  </span>
                </span>

                <!-- INPUT SAMPLE -->
                <span style="display: flex; width: 100%; padding-bottom: 16px; padding-top: 6px;">
                  <span style="width: 2in; font-weight: bold; float: left;">
                    Prepared by
                  </span>
                  <span style="width: 5.5in; float: left;">
                    <span class="editable-content" :class="highlightEditable ? 'highlighted' : ''">
                      <div class="editable-button">
                        <v-menu
                          offset-y
                          top
                          :close-on-content-click="false"
                          attach
                          min-width="350"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              block
                              style="height: 100%;"
                              color="transparent"
                              depressed
                              icon
                            >
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item
                            >
                              <v-text-field
                                v-if="coverSheetSample[1].type === 'input'"
                                v-model="coverSheetSample[1].value"
                                hide-details
                              ></v-text-field>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                      {{ coverSheetSample[1].value }}
                    </span>
                  </span>
                </span>

                <!-- RADIO SAMPLE -->
                <span style="display: flex; width: 100%; padding-bottom: 16px; padding-top: 6px;">
                  <span style="width: 2in; font-weight: bold; float: left;">
                    Action Required
                  </span>
                  <span style="width: 0.25in; float: left;">
                    <span class="editable-content" :class="highlightEditable ? 'highlighted' : ''">
                      <input type="radio" name="test" value="Yes" v-model="coverSheetSample[2].value" />
                    </span>
                  </span>
                  <span style="width: 1in; float: left;">
                    Yes
                  </span>
                  <span style="width: 0.25in; float: left;">
                    <span class="editable-content" :class="highlightEditable ? 'highlighted' : ''">
                      <input type="radio" name="test" value="No" v-model="coverSheetSample[2].value" />
                    </span>
                  </span>
                  <span style="width: 1in; float: left;">
                    No
                  </span>
                  <span style="width: 3.0in; float: left;">
                    &nbsp;
                  </span>
                </span>

                <span style="display: flex; width: 100%; padding-bottom: 6px; padding-top: 6px;">
                  <span style="width: 7.5in; font-weight: bold; float: left;">
                    Summary
                  </span>
                </span>

                <!-- TEXTAREA SAMPLE -->
                <span style="display: flex; width: 100%; padding-bottom: 16px; padding-top: 6px;">
                  <span style="width: 7.5in; float: left;">
                    <span class="editable-content" :class="highlightEditable ? 'highlighted' : ''">
                      <div class="editable-button">
                        <v-menu
                          offset-y
                          top
                          :close-on-content-click="false"
                          attach
                          min-width="350"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              block
                              style="height: 100%;"
                              color="transparent"
                              depressed
                              icon
                            >
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item
                            >
                              <v-textarea
                                v-if="coverSheetSample[3].type==='textarea'"
                                hide-details
                                v-model="coverSheetSample[3].value"
                              >
                              </v-textarea>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                      {{ coverSheetSample[3].value }}
                    </span>
                  </span>
                </span>

                <span style="width: 100%; padding-bottom: 6px; padding-top: 6px;">
                  <span style="width: 7.5in; font-weight: bold; float: left;">
                    Fiscal Considerations
                  </span>
                </span>

                <!-- TEXTAREA SAMPLE -->
                <span style="display: flex; width: 100%; padding-bottom: 16px; padding-top: 6px;">
                  <span style="width: 7.5in; float: left;">
                    <span class="editable-content" :class="highlightEditable ? 'highlighted' : ''">
                      <div class="editable-button">
                        <v-menu
                          offset-y
                          top
                          :close-on-content-click="false"
                          attach
                          min-width="350"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              block
                              style="height: 100%;"
                              color="transparent"
                              depressed
                              icon
                            >
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item
                            >
                              <v-textarea
                                v-if="coverSheetSample[4].type==='textarea'"
                                hide-details
                                v-model="coverSheetSample[4].value"
                              >
                              </v-textarea>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                      {{ coverSheetSample[4].value }}
                    </span>
                  </span>
                </span>

                <!-- CHECKBOX SAMPLE -->
                <span style="display: flex; width: 100%; padding-bottom: 16px; padding-top: 6px;">
                  <span style="width: 0.25in; float: left;">
                    <span class="editable-content" :class="highlightEditable ? 'highlighted' : ''">
                      <input type="checkbox" v-model="coverSheetSample[5].value">
                    </span>
                  </span>
                  <span style="width: 7.25in; float: left;">
                    {{ coverSheetSample[5].label }}
                  </span>
                </span>

                <!-- SELECT SAMPLE -->
                <span style="display: flex; width: 100%; padding-bottom: 16px; padding-top: 6px;">
                  <span style="width: 2in; font-weight: bold; float: left;">
                    Select Sample
                  </span>
                  <span style="width: 5.5in; float: left;">
                    <span class="editable-content" :class="highlightEditable ? 'highlighted' : ''">
                      <div class="editable-button">
                        <v-menu
                          offset-y
                          top
                          :close-on-content-click="false"
                          attach
                          min-width="350"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              block
                              style="height: 100%;"
                              color="transparent"
                              depressed
                              icon
                            >
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item
                              v-for="item in coverSheetSample[6].options" :key="item.label"
                              @click="changeSelect(item.label)"
                            >
                              {{ item.label }}
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                      {{ coverSheetSample[6].value }}
                    </span>
                  </span>
                </span>

              </v-card>
            </div>

          </v-card-text>

          <v-card-actions class="d-flex align-center justify-center">
            <v-spacer></v-spacer>
            <v-btn depressed width="130" @click="show=false">Ok</v-btn>
          </v-card-actions>

        </v-tab-item>

        <v-tab-item value="tab-1">

          <v-card-text class="pa-16">

            <v-row
              v-for="(section,n) in sections" :key="n"
            >
              <v-col>
                <v-row>
                  <v-col class="d-flex pb-0 text-uppercase font-weight-medium">
                    <label>{{ section.original_section.name }}</label>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>

                    <v-textarea
                      v-if="section.original_section.content_type === 'textarea'"
                      outlined
                      v-model="section.content"
                      hide-details
                      auto-grow
                    ></v-textarea>

                    <v-text-field
                      v-if="section.original_section.component_type === 'text'"
                      outlined
                      v-model="section.content"
                      hide-details
                    ></v-text-field>

                    <v-radio-group
                      v-if="section.original_section.component_type === 'radio'"
                      v-model="section.content"
                      hide-details
                    >
                      <v-radio
                        v-for="(radioOption,opt) in JSON.parse(section.original_section.possible_values)"
                        :key="opt"
                        :label="radioOption.label"
                        :value="section.content"
                      ></v-radio>
                    </v-radio-group>

                    <v-select
                      v-if="section.original_section.component_type === 'select'"
                      v-model="section.content"
                      :items="JSON.parse(section.original_section.possible_values)"
                      item-text="label"
                      outlined
                      hide-details
                    ></v-select>

                    <v-checkbox
                      v-if="section.original_section.component_type === 'checkbox'"
                      class="pa-0 ma-0"
                      :label="section.original_section.possible_values.label"
                      hide-details
                      v-model="section.content"
                    ></v-checkbox>

                  </v-col>
                </v-row>

              </v-col>
            </v-row>

          </v-card-text>

          <v-card-actions class="d-flex align-center justify-center">
            <v-spacer></v-spacer>
            <v-btn depressed width="130" @click="show=false">Ok</v-btn>
          </v-card-actions>

        </v-tab-item>

      </v-tabs-items>

      <span v-if="editStructure">

        <v-tabs
          v-model="tabStructure"
          color="transparent"
          grow class="d-none"
        >
        </v-tabs>

        <v-tabs-items v-model="tabStructure" class="transparent">

          <v-tab-item key="0">

            <v-card-title class="mb-12" style="height: 70px;">

              <v-row>
                <v-col>
                  <v-btn
                    depressed
                    icon
                    @click="editStructure = false"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <span>Rows of <span class="font-weight-black">Name of the Cover Sheet</span></span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="darkGrey"
                        size="18"
                        class="ml-3"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>
                      Explanation for this area
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col class="d-flex justify-end align-center">
                  <v-switch
                    inset
                    label="Preview"
                    color="secondary"
                    v-model="previewRegion"
                    class="mt-0"
                    hide-details
                  ></v-switch>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-3 ml-5"
                      >
                        <v-icon size="32">mdi-rotate-left</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      Revert to Original
                    </span>
                  </v-tooltip>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        height="40"
                        color="orange lighten-2"
                        class="white--text ml-8"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon size="18">mdi-plus</v-icon> Add Row
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item @click="addRow">
                        <v-list-item-title>Duplicate last row</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="addRow">
                        <v-list-item-title>Empty</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>

            </v-card-title>

            <v-card-text class="pa-3 mb-6 overflow-auto">

              <div class="letter-wrapper">
                <v-card
                  elevation="8"
                  class="d-flex flex-column rounded-0 letter-paper"
                  style="padding: 0.5in"
                >

                  <span class="d-flex row-editor mb-6">

                    <div class="d-flex" style="position: relative">
                      <div
                        class="edit-cell d-flex align-center"
                        :class="{ 'table-preview' : previewRegion }"
                        style="width: 3.75in"
                      >
                        Test 1
                      </div>
                      <div
                        class="edit-cell d-flex align-center"
                        :class="{ 'table-preview' : previewRegion }"
                        style="width: 2.0in"
                      >
                        Test 2
                      </div>
                      <div
                        class="edit-cell d-flex align-center"
                        :class="{ 'table-preview' : previewRegion }"
                        style="width: 1.75in"
                      >
                        Test 3
                      </div>
                      <span class="edit-column">
                        <v-menu
                          offset-x
                          open-on-hover
                          min-width="30"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                              color="transparent"
                              tile
                              block
                              @click="goToRow"
                            >
                              <v-icon>mdi-vertical-dots</v-icon>
                            </v-btn>
                          </template>
                          <v-list
                            dense
                          >
                            <v-list-item class="d-flex justify-center">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="secondary">mdi-table-row-plus-before</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  Insert row before
                                </span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="goToRow"
                                  >
                                    <v-icon>mdi-pencil</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  Edit row
                                </span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-chevron-up</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  Move row up
                                </span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-chevron-down</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  Move row down
                                </span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @mouseenter="addRegionMenu=false"
                                  >
                                    <v-icon>mdi-format-page-break</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  Insert Page Break
                                </span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="important">mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  Delete row
                                </span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="secondary">mdi-table-row-plus-after</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  Insert row after
                                </span>
                              </v-tooltip>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </span>
                    </div>

                  </span>

                </v-card>
              </div>

            </v-card-text>

            <v-card-actions class="d-flex align-center justify-center">
              <v-spacer></v-spacer>
              <v-btn depressed width="130" @click="show=false">Ok</v-btn>
            </v-card-actions>

          </v-tab-item>

          <v-tab-item key="1">

            <v-card-title class="mb-0" style="height: 70px;">

              <v-row>
                <v-col>
                  <v-btn
                    depressed
                    icon
                    @click="goToRows"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <span>Row</span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="darkGrey"
                        size="18"
                        class="ml-3"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>
                      Explanation for this area
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col class="d-flex justify-end align-center">
                  <v-switch
                    inset
                    label="Preview"
                    color="secondary"
                    v-model="previewRow"
                    class="mt-0"
                    hide-details
                  ></v-switch>
                </v-col>
              </v-row>

            </v-card-title>

            <v-card-text class="mb-6" style="min-height: 450px;">

              <v-row>
                <v-col class="pb-0">
                  <v-tabs
                    v-model="rowColumns"
                    centered
                    color="secondary"
                  >
                    <v-tab>1</v-tab>
                    <v-tab>2</v-tab>
                    <v-tab>3</v-tab>
                    <v-tab>4</v-tab>
                  </v-tabs>
                </v-col>
              </v-row>

              <v-row class="d-flex mb-8">
                <v-col
                  class="veryLightGrey pa-8"
                >

                  <v-row>
                    <v-col class="py-0">
                      <v-textarea
                        height="80"
                        class="white mt-0 pt-0 rounded-0"
                        hide-details
                        outlined
                        no-resize
                      ></v-textarea>
                    </v-col>
                  </v-row>

                </v-col>
              </v-row>

              <v-row>
                <v-col
                >

                  <div class="letter-wrapper">
                    <v-card
                      elevation="8"
                      class="d-flex flex-column rounded-0 letter-width"
                      style="padding: 0.5in"
                    >

                      <span class="d-flex row-editor mb-6">

                        <div class="d-flex">
                          <div
                            class="edit-cell d-flex align-center"
                            v-for="(item,key) in rowContent"
                            ref="rowCol"
                            :key="'col' + key"
                            @click="selectColumn(key)"
                            :class="{ 'table-preview' : previewRow }"
                          >
                            {{ item }}
                            <span class="edit-column">
                              <v-menu
                                offset-y
                                open-on-hover
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    color="transparent"
                                    tile
                                    block
                                    @click="selectColumn(key)"
                                  >
                                    <v-icon>mdi-vertical-dots</v-icon>
                                  </v-btn>
                                </template>
                                <v-list
                                  dense
                                >
                                  <v-list-item class="d-flex justify-center">
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon color="secondary">mdi-table-column-plus-before</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Insert column before
                                      </span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                          @click="selectColumn(key)"
                                        >
                                          <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Edit column
                                      </span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon color="important">mdi-delete</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Delete column
                                      </span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon color="secondary">mdi-table-column-plus-after</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Insert column after
                                      </span>
                                    </v-tooltip>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </span>
                          </div>
                        </div>

                      </span>

                      <vue-slider
                        v-model="columnDots"
                        v-bind="sliderOptions"
                        class="custom-slider"
                        @change="updateRowColumns"
                        v-if="!previewRow"
                        :enable-cross="false"
                      ></vue-slider>

                    </v-card>
                  </div>

                </v-col>
              </v-row>

            </v-card-text>

            <v-card-actions class="d-flex align-center justify-center">
              <v-spacer></v-spacer>
              <v-btn depressed width="130" @click="goToRows">Cancel</v-btn>
              <v-btn depressed width="130" color="secondary">Update</v-btn>
            </v-card-actions>

          </v-tab-item>

        </v-tabs-items>

      </span>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import axios from 'axios'

function initialData () {
  return {
    loading: false,
    sections: [],
    editStructure: false,
    tab: null,
    tabStructure: 0,
    highlightEditable: true,
    fab: false,
    tabRegion: 0,
    addRegionMenu: false,
    rowColumns: 0,
    rowContent: [
      '',
      '',
      '',
      ''],
    columnDots: [0, 1.5, 1.75, 4.5, 7.5],
    sliderOptions: {
      process: false,
      tooltipPlacement: 'bottom',
      adsorb: true,
      clickable: false,
      interval: 0.250,
      max: 7.5,
      min: 0,
      minRange: 0.250
    },
    previewRegion: false,
    previewRow: false,
    rowSample: [
      { text: 'Name' },
      { text: 'Administrator' },
      { text: 'Email' },
      { text: 'Workgroups' },
      { text: 'Actions' }
    ],
    coverSheetSample: [
      {
        type: 'textarea',
        title: 'Name of Data Section Component 1',
        value: 'Integer aliquet tortor at lobortis malesuada. Pellentesque eleifend metus in vulputate sollicitudin. In aliquam risus at lectus semper, id vestibulum leo euismod. Aenean lacinia scelerisque orci.'
      },
      {
        type: 'input',
        title: 'Name of Data Section Component 2',
        value: 'Charles Simon'
      },
      {
        type: 'radio',
        title: 'Name of Data Section Component 3',
        value: 'No',
        options: [
          { label: 'Yes' },
          { label: 'No' }
        ]
      },
      {
        type: 'textarea',
        title: 'Name of Data Section Component 4',
        value: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea.'
      },
      {
        type: 'textarea',
        title: 'Name of Data Section Component 5',
        value: 'Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea..'
      },
      {
        type: 'checkbox',
        title: 'Name of Data Section Component 6',
        value: true,
        label: 'Sample label'
      },
      {
        type: 'select',
        title: 'Name of Data Section Component 7',
        value: 'Region 1',
        options: [
          { label: 'Region 1' },
          { label: 'Region 2' },
          { label: 'Region 3' },
          { label: 'Region 4' },
          { label: 'Region 5' }
        ]
      }
    ]
  }
}

export default Vue.extend({
  name: 'ItemCoverSheetDraft',
  components: {
    VueSlider
  },
  props: {
    item: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => initialData(),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    boardName () {
      return this.item.board?.name
    }
  },
  watch: {
    numberingSelected: {
      handler () {
        for (let i = 0; i < 5; i++) {
          if (this.numberingSelected[i].selected === 'previous') {
            this.numberingSelected[i].symbol = this.numberingSelected[i - 1].symbol
          } else {
            this.numberingSelected[i].symbol = this.numberingSelected[i].selected
          }
        }
      },
      deep: true
    },
    show (value) {
      if (value) {
        this.getSectionsForCurrentItemType()
      }
    }
  },
  methods: {
    changeSelect (value) {
      this.coverSheetSample[6].value = value
    },
    goToRows () {
      this.tabStructure = 0
    },
    goToRow () {
      this.tabStructure = 1
      setTimeout(this.updateRowColumns, 200)
    },
    updateRowColumns () {
      const totalCols = this.columnDots.length
      for (let i = 0; i < totalCols; i++) {
        if (i < (totalCols - 1)) {
          const columnWidth = (this.columnDots[i + 1] - this.columnDots[i]) + 'in'
          this.$refs.rowCol[i].style.width = columnWidth
        } else {
          const columnWidth = (this.sliderOptions.max - this.columnDots[i]) + 'in'
          if ((this.sliderOptions.max - this.columnDots[i]) >= this.sliderOptions.interval) {
            this.$refs.rowCol[i].style.width = columnWidth
          }
        }
      }
    },
    selectColumn (key) {
      this.rowColumns = key
    },
    deleteRow () {
      console.log('delete cover sheet row')
    },
    addRow () {
      console.log('add new cover sheet row')
    },
    getSectionsForCurrentItemType () {
      this.loading = true
      axios({
        method: 'get',
        url: process.env.VUE_APP_BACKEND_URL + '/api/items/getSectionsBasedOnItem/' + this.item.id
      }).then((response) => {
        console.log('response data', response.data)
        this.sections = response.data
        this.loading = false
      })
    }
  }
})

/*
All <textarea> using WYSIWYG
*/
</script>
<style>

  .highlighted {
    outline: 1px solid #65ada5;
  }

  .editable-content {
    position: relative;
    float: left;
  }

  .editable-button {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%
  }

</style>
