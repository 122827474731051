<template>

  <v-dialog
    width="750"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Cover Sheet of <span class="font-weight-black">{{ item.title }}</span></span>
        <span>for <span class="font-weight-black">{{ boardName }}</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row class="d-flex">
          <v-col class="d-flex align-center">
            <v-switch
              inset
              color="secondary"
              class="mt-0 pa-0 mr-8 py-4"
              label="Use Cover Sheet"
              hide-details
              v-model="hasCoversheet"
            >
            </v-switch>
            <span v-if="!hasCoversheet">
              <v-icon color="orange lighten-3">mdi-alert</v-icon> Message about not using a Cover Sheet
            </span>
          </v-col>
          <v-col class="d-flex justify-end" v-if="hasCoversheet">
            <v-btn
              depressed
              text
              color="secondary"
              @click="useCoversheetTemplate = false"
              v-if="useCoversheetTemplate"
            >
              Want to upload a cover sheet?
            </v-btn>
            <v-btn
              depressed
              text
              color="secondary"
              @click="useCoversheetTemplate = true"
              v-if="!useCoversheetTemplate"
            >
              Want to use an automatic cover sheet?
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          v-if="hasCoversheet && useCoversheetTemplate"
          class="d-flex"
        >
          <v-col>
            <v-select
              label="Cover Sheet Template"
              outlined
              small
              v-model="form.cover_sheet_id"
              :items="coverSheets"
              :item-value="item => item.id"
              :item-text="item => item.name"
            ></v-select>
          </v-col>
        </v-row>

        <v-row
          v-if="hasCoversheet && !useCoversheetTemplate"
          class="d-flex"
        >
          <v-col>
            <v-card
              elevation="0"
              class="d-flex align-center justify-center"
              height="200"
              style="border: 1px dashed rgba(0,0,0,0.2);"
            >
              <v-icon color="lightGrey">mdi-cloud-upload</v-icon>
              <span class="lightGrey--text d-flex ml-4 text-caption text-uppercase">Click or drag/drop to add file</span>
            </v-card>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="updateCoverSheet">Update</v-btn>
      </v-card-actions>

    </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import CoverSheet from '@/models/CoverSheet'
import axios from 'axios'

function initialState () {
  return {
    hasCoversheet: false,
    useCoversheetTemplate: true,
    saving: false,
    valid: false,
    coverSheets: [],
    form: {
      cover_sheet_id: ''
    },
    coverSheetList: ['Name of Cover Sheet 1', 'Name of Cover Sheet 2', 'Name of Cover Sheet 3']
  }
}

export default Vue.extend({
  name: 'ItemCoverSheet',
  props: {
    item: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => initialState(),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    boardName () {
      return this.item.board?.name
    }
  },
  methods: {
    resetModal () {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      Object.assign(this.$data, initialState())
      this.saving = false
    },
    getCoverSheets () {
      CoverSheet
        .where('item_type_id', this.item.item_type_id)
        .get()
        .then(coverSheetResponse => {
          this.coverSheets = coverSheetResponse.data
          console.log('this.coverSheets', this.coverSheets)
        })
    },
    updateCoverSheet () {
      const formPassedValidation = this.$refs.form.validate()
      if (formPassedValidation === false) {
        return false
      }
      this.saving = true
      if (!this.hasCoversheet) {
        this.form.cover_sheet_id = ''
      }
      axios({
        method: 'put',
        url: process.env.VUE_APP_BACKEND_URL + '/api/items/' + this.item.id,
        data: this.form
      }).then(() => {
        console.log('success')
        this.resetModal()
        this.show = false
        this.$emit('reload', true)
      }).catch(error => {
        console.log('error', error)
        // console.log(error.response.data)
        // console.log(error.response.status)
        // console.log(error.response.headers)
      })
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.getCoverSheets()
        if (this.item.cover_sheet_id) {
          this.hasCoversheet = true
          this.form.cover_sheet_id = this.item.cover_sheet_id
        }
      }
    }
  }

})
</script>
