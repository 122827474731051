<template>
  <v-navigation-drawer
    fixed
    temporary
    right
    width="600"
    v-model="show"
  >

    <div class="py-6 px-12">

      <v-row>
        <v-col>
          <h1 class="darkGrey--text font-weight-medium d-flex flex-column">
            <span>Item of</span>
            <span>{{ boardName }}</span>
          </h1>
        </v-col>
      </v-row>

      <v-row class="mb-10">
        <v-col class="d-flex align-start flex-column justify-start" cols="5">
          <span class="d-flex align-center justify-start" v-if="!item.is_parked">
            <v-icon color="secondary" size="24">mdi-account-group</v-icon>
            <span class="secondary--text text-uppercase text-caption font-weight-medium ml-2">In a meeting</span>
          </span>
          <span class="d-flex align-center justify-start" v-else>
            <v-icon color="lightGrey" size="24">mdi-sign-caution</v-icon>
            <span class="lightGrey--text text-uppercase text-caption font-weight-medium ml-2">Parked</span>
          </span>
        </v-col>
        <v-col class="d-flex align-center justify-end" cols="7">
            <span class="d-flex align-center">
              <v-icon color="secondary" size="24">mdi-account</v-icon>
              <span class="secondary--text text-uppercase text-caption font-weight-medium ml-2 mr-3">Owner</span>
              <span>{{ itemOwner }}</span>
            </span>
        </v-col>
      </v-row>

      <v-row class="d-flex" :class="itemAlias ? 'mb-16' : 'mb-0'">
        <v-col v-if="itemAlias">
          <span>
            {{ item.alias }}
          </span>
        </v-col>
        <v-col class="d-flex" :class="itemAlias ? 'justify-end' : 'justify-start'">
          <v-switch
            inset
            color="secondary"
            class="mt-0 pa-0"
            hide-details
            label="Display alias"
            dense
            v-model="itemAlias">
          </v-switch>
        </v-col>
      </v-row>

      <v-row class="d-flex mb-16" v-if="!itemAlias">
        <v-col>
          <v-card elevation="0" class="text-caption">
            <span v-if="!itemComplete">{{ item.title.slice(0,300) }}...</span>
            <span v-if="itemComplete">{{ item.title }}</span>
          </v-card>
          <v-btn
            depressed
            color="veryLightGrey"
            class="text-caption mt-4"
            @click="itemComplete = !itemComplete"
            v-if="item.title.length > 300"
          >
            <span v-if="!itemComplete">Show more</span>
            <span v-if="itemComplete">Show less</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5" class="d-flex align-center">
          <v-icon color="secondary" size="34">
            mdi-clock-outline
          </v-icon>
          <span class="secondary--text text-uppercase text-caption ml-2">
            Date and Time
          </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6">
          <span class="text-body-1 d-flex justify-end align-center text-right text-body-1">
            {{ createdDate }}
          </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5" class="d-flex align-center">
          <v-icon color="secondary" size="34">
            mdi-view-compact
          </v-icon>
          <span class="secondary--text text-uppercase text-caption ml-2">
            Agenda Style
          </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6">
          <span class="text-body-1 d-flex justify-end align-center text-right text-body-2">
            Name of Agenda Style
          </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5" class="d-flex align-center">
          <v-icon color="secondary" size="34">
            mdi-table
          </v-icon>
          <span class="secondary--text text-uppercase text-caption ml-2">
            Region
          </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6">
          <span class="text-body-1 d-flex justify-end align-center text-right text-body-2">
            Name of Region
          </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5" class="d-flex align-center">
          <v-icon color="secondary" size="34">
            mdi-format-list-bulleted-type
          </v-icon>
          <span class="secondary--text text-uppercase text-caption ml-2">
            Item Type
          </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6">
          <span class="text-body-1 d-flex justify-end align-center text-right text-body-2">
            {{ itemTypeName }}
          </span>
        </v-col>
      </v-row>

      <v-row class="mb-16">
        <v-col cols="5" class="d-flex align-center">
          <v-icon color="secondary" size="34">
            mdi-file
          </v-icon>
          <span class="secondary--text text-uppercase text-caption ml-2">
            Cover Sheet
          </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6">
           <span class="text-body-1 d-flex justify-end align-center text-right text-body-2" v-if="coverSheetName">
            {{ coverSheetName }}
          </span>
          <span class="text-body-1 d-flex justify-end align-center text-right text-body-2" v-else>
            <v-icon class="d-flex mr-2">mdi-alert</v-icon> No cover sheet selected
          </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
              <span class="d-flex align-center">
                <v-icon color="secondary" size="30">mdi-briefcase</v-icon>
                <span class="text-uppercase darkGrey--text ml-4 font-weight-medium text-body-1">2 Workgroups</span>
              </span>
        </v-col>
      </v-row>
      <v-row class="mb-16">
        <v-col>
          <v-expansion-panels flat accordion class="custom-panel-separator">
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0">
                <span class="font-weight-light">
                  City Manager Office
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0 veryLightGrey">
                <span class="d-flex flex-column pt-4 darkGrey--text text-body-2">
                  <span class="mb-6 text-caption">
                    Duis at interdum lectus. Aenean non justo eget mi bibendum egestas sit amet eleifend sapien. Phasellus egestas faucibus tortor, vel fringilla purus dignissim feugiat.
                  </span>
                  <span>
                    <span class="font-weight-bold">11 MEMBERS</span><span class="text-caption ml-4">that can VIEW and EDIT</span>
                  </span>
                  <span class="text-body-2 custom-view-list">
                    <ul class="custom-list-very-big">
                      <li class="font-weight-light" v-for="item in membersSample" :key="item">
                        {{ item }}
                      </li>
                    </ul>
                  </span>
                </span>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0">
                <span class="font-weight-light">
                  City Secretary Office
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0 veryLightGrey">
                <span class="d-flex flex-column pt-4 darkGrey--text text-body-2">
                  <span class="mb-6 text-caption">
                    Duis at interdum lectus. Aenean non justo eget mi bibendum egestas sit amet eleifend sapien. Phasellus egestas faucibus tortor, vel fringilla purus dignissim feugiat.
                  </span>
                  <span>
                    <span class="font-weight-bold">11 MEMBERS</span> that can VIEW and EDIT
                  </span>
                  <span class="text-body-2 custom-view-list">
                    <ul class="custom-list-very-big">
                      <li class="font-weight-light" v-for="item in membersSample" :key="item">
                        {{ item }}
                      </li>
                    </ul>
                  </span>
                </span>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row class="mb-16 d-flex flex-column">
        <v-col>
          <v-row>
            <v-col class="d-flex align-center">
              <span class="d-flex align-center">
                <v-icon color="secondary" size="30">mdi-timeline-text-outline</v-icon>
                <span class="text-uppercase darkGrey--text ml-4 font-weight-medium text-body-1">Activities</span>
              </span>
              <v-switch
                inset
                color="secondary"
                class="mt-0 pa-0 ml-8"
                hide-details
                dense
                v-model="showActivities"
              ></v-switch>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="showActivities">

          <v-timeline
            align-top
            dense
          >
            <v-timeline-item
              fill-dot
              color="secondary"
              icon="mdi-share"
            >
              <span class="d-flex flex-column">
                <span class="d-flex flex-column">
                  <span>
                    <span class="font-weight-bold">Shared</span> by <span class="font-weight-bold">Charles Simon</span>
                  </span>
                  <span class="text-caption">on Tue, Dec 18, 2018 5:21 AM</span>
                </span>
                <span class="text-caption">
                  The item was shared with Noa Bender with the properties view
                </span>
              </span>
            </v-timeline-item>
            <v-timeline-item
              fill-dot
              color="secondary"
              icon="mdi-pencil"
            >
              <span class="d-flex flex-column">
                <span class="d-flex flex-column">
                  <span>
                    <span class="font-weight-bold">Edited</span> by <span class="font-weight-bold">Charles Simon</span>
                  </span>
                  <span class="text-caption">on Tue, Dec 18, 2018 5:21 AM</span>
                </span>
                <span class="text-caption">
                  The item title has been changed from:<br>
                  This is the title before.<br>
                  To:<br>
                  This is the title after it was revised.
                </span>
              </span>
            </v-timeline-item>
            <v-timeline-item
              fill-dot
              color="primary"
              icon="mdi-file-outline"
            >
              <span class="d-flex flex-column">
                <span class="d-flex flex-column">
                  <span>
                    <span class="font-weight-bold">Created</span> by <span class="font-weight-bold">Charles Simon</span>
                  </span>
                  <span class="text-caption">on Tue, Dec 18, 2018 5:21 AM</span>
                </span>
                <span class="text-caption">
                  Meeting was created and placed in the Thursday, July 18, 2019 7:00 PM - Parks and Recreation Commission - Regular Meeting
                </span>
              </span>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>

      <v-btn depressed @click="show=false">
        Close
      </v-btn>

    </div>

  </v-navigation-drawer>

</template>

<script>
import Vue from 'vue'

function initialState () {
  return {
    showActivities: true,
    itemTitle: 'Proin sed purus arcu. Nam ut elementum felis, vel convallis neque. Maecenas ultricies euismod urna vel suscipit. Aenean velit sem, posuere nec mauris in, interdum condimentum nisl. Donec sodales accumsan lacus id dignissim. Mauris sed odio lacus. Nullam arcu nulla, ultrices ac malesuada quis, euismod in turpis. Mauris vel facilisis ex, nec imperdiet tellus. Maecenas vel felis tincidunt, cursus diam ut, vehicula orci. Aliquam vestibulum, turpis quis scelerisque molestie, sapien ipsum laoreet velit, ut vehicula magna urna sit amet eros. Suspendisse faucibus condimentum lectus non vehicula. Morbi vehicula augue risus, dignissim cursus enim sollicitudin a. Aliquam et arcu justo.\n' +
      '\n' +
      'Duis congue turpis eu efficitur commodo. Maecenas accumsan consequat ante at semper. In eu facilisis ex. Donec convallis pellentesque est in semper. Integer ac ipsum vitae quam porta sollicitudin. Aliquam ut massa tempor, pretium metus et, gravida ante. Quisque faucibus et mi ut placerat. Sed vitae enim eget nisl lobortis sodales. Nullam ut nisi ligula. Curabitur vel elementum tellus. Vivamus sit amet est ut augue rutrum suscipit ac et neque. Mauris a massa ante. Vestibulum viverra feugiat diam, vitae vestibulum mi feugiat faucibus. Cras facilisis massa sem, sed rhoncus nisl pellentesque id. Praesent laoreet mauris eget interdum congue.',
    itemAlias: false,
    itemComplete: false,
    membersSample: ['Charles Simon', 'Vee Caron', 'Rico Reis', 'Raph Leroux', 'Tony Cappozi', 'Kyler Miles', 'Sean Canlas', 'Stephen Cawley', 'Arthur da Silva', 'Miguel Reis', 'Alline Pereira']
  }
}

export default Vue.extend({
  name: 'ItemView',
  props: {
    item: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => initialState(),
  methods: {
    close () {
      this.$emit('closed')
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    boardName () {
      return this.item.board?.name
    },
    createdDate () {
      const date = new Date(this.item.created_at)
      return date.toDateString() + ' at ' + date.toLocaleTimeString()
    },
    itemOwner () {
      return this.item.user?.first_name + ' ' + this.item.user?.last_name
    },
    coverSheetName () {
      return this.item.cover_sheet?.name
    },
    itemTypeName () {
      return this.item.item_type?.type
    }
  },
  watch: {
    show (value) {
      if (value) {
        if (this.item.alias !== undefined && this.item.alias.length > 0) {
          this.itemAlias = true
        }
        this.itemComplete = this.item.title.length > 300
      }
    }
  }
})
</script>
