<template>

  <v-dialog
    width="750"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Change Region of <span class="font-weight-black">{{ item.title }}</span></span>
        <span>for <span class="font-weight-black">{{ boardName }}</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col>
            <v-select
              label="Region"
              outlined
              small
              hide-details
              :items="regionList"
            ></v-select>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="saveChangeRegion">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: 'ItemChangeRegion',
  props: {
    item: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    regions: [],
    regionList: ['Name of Region 1', 'Name of Region 2', 'Name of Region 3']
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    boardName () {
      return this.item.board?.name
    }
  },
  methods: {
    saveChangeRegion () {
      console.log('save change region')
      this.show = false
    }
  },
  watch: {
    show (value) {
      if (value) {
        // TODO: Change URL agenda-style/get-regions-from-associated-agenda/{agenda_id}
        // Fix up everything and pull only these
        // CHANGING REGION SHOULD BE PULLED FROM THE MEETINGS AFTER IT WAS CLONED.
        // ON THE CREATE MEETINGS PAGE, THEN ALL THE OPTIONS SHOULD SHOW. THIS NEEDS WORK!
        axios.get(process.env.VUE_APP_BACKEND_URL + '/api/item-type/get-regions-from-associated-agenda/' + this.item.item_type_id)
          .then((res) => {
            console.log('res', res)
            this.regions = res.data
          })
      }
    }
  }
})
</script>
